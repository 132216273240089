<template>
    <page>
        <content-area v-if="recentAlbums.length">
            <gallery
                :items="recentAlbums"
                name="Recent Albums"
            />
        </content-area>
        <content-area v-if="recentActivityAlbums.length">
            <gallery
                :items="recentActivityAlbums"
                name="Recent Activity"
            />
        </content-area>
    </page>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Page from '../layouts/Page.vue';
import Gallery from '../components/Presentation/Gallery.vue';
import ContentArea from '../components/Presentation/ContentArea.vue';

export default {
    name: 'Home',
    components: { ContentArea, Gallery, Page },
    async mounted() {
        await this.fetchRecentAlbums();
        await this.fetchRecentActivityAlbums();
    },
    computed: {
        ...mapState('recentAlbums', ['recentAlbums']),
        ...mapState('recentActivityAlbums', ['recentActivityAlbums']),
    },
    methods: {
        ...mapActions('recentAlbums', ['fetchRecentAlbums']),
        ...mapActions('recentActivityAlbums', ['fetchRecentActivityAlbums']),
    },
};
</script>
